import { inject, Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceDetectorService } from "ngx-device-detector";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { RightSlideOutPanelComponent } from "../../pages/common/right-slide-out-panel/right-slide-out-panel.component";
import { SnackBarComponent } from "../components/snack-bar/snack-bar.component";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  slideOutDialog: MatDialog = inject(MatDialog);

  private readonly _summaryView = new BehaviorSubject<boolean>(false);
  set summaryView(value: boolean) {
    this._summaryView.next(value);
  }
  get summaryView() {
    return this._summaryView.getValue();
  }
  getSummaryView: Observable<boolean> = this._summaryView.asObservable();

  private readonly _loadPermissions = new BehaviorSubject<boolean>(false);
  set loadPermissions(value: any) {
    this._loadPermissions.next(value);
  }
  get loadPermissions() {
    return this._loadPermissions.getValue();
  }
  permissionsLoaded: Observable<any> = this._loadPermissions.asObservable();

  private _modules = new BehaviorSubject<any>({} as any);
  set modules(value: any) {
    this._modules.next(value);
  }
  get modules() {
    return this._modules.getValue();
  }
  getModule: Observable<any> = this._modules.asObservable();

  private readonly _adminSettings = new BehaviorSubject<any>(null);
  set adminSettings(value: any) {
    this._adminSettings.next(value);
  }
  get adminSettings() {
    return this._adminSettings.getValue();
  }
  getAdminSettings: Observable<any> = this._adminSettings.asObservable();

  private readonly _isDarkMode = new BehaviorSubject<boolean>(false);
  set isDarkMode(value: boolean) {
    this._isDarkMode.next(value);
  }
  get isDarkMode() {
    return this._isDarkMode.getValue();
  }
  isDarkMode$: Observable<boolean> = this._isDarkMode.asObservable();

  private readonly _isSuperAdmin = new BehaviorSubject<boolean>(false);
  set isSuperAdmin(value: boolean) {
    this._isSuperAdmin.next(value);
  }
  get isSuperAdmin() {
    return this._isSuperAdmin.getValue();
  }
  isSuperAdmin$: Observable<boolean> = this._isSuperAdmin.asObservable();

  private readonly _isEAUser = new BehaviorSubject<boolean>(false);
  set isEAUser(value: boolean) {
    this._isEAUser.next(value);
  }
  get isEAUser() {
    return this._isEAUser.getValue();
  }
  isEAUser$: Observable<boolean> = this._isEAUser.asObservable();

  private readonly _isCustAdmin = new BehaviorSubject<boolean>(false);
  set isCustAdmin(value: boolean) {
    this._isCustAdmin.next(value);
  }
  get isCustAdmin() {
    return this._isCustAdmin.getValue();
  }
  isCustAdmin$: Observable<boolean> = this._isCustAdmin.asObservable();

  private readonly _customersList = new BehaviorSubject<any[]>([]);
  set customersList(value: any[]) {
    this._customersList.next(value);
  }
  get customersList() {
    return this._customersList.getValue();
  }
  getCstomersList: Observable<any[]> = this._customersList.asObservable();

  private readonly _user = new BehaviorSubject<any>({} as any);
  set user(value: any) {
    this._user.next(value);
  }
  get user() {
    return this._user.getValue();
  }
  getUser: Observable<any> = this._user.asObservable();

  deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);

  isMobile = this.deviceDetectorService.isMobile();
  isTablet = this.deviceDetectorService.isTablet();
  isDesktopDevice = this.deviceDetectorService.isDesktop();
  currentDeviceType = this.deviceDetectorService.deviceType;

  customerId: number = 0;
  siteId: number = 0;
  subStationId: number = 0;
  assetId: number = 0;
  subAssetId: number = 0;
  feederNo: string = "";
  offsetDate: string = "";

  constructor() {
    this.getUser.subscribe((user) => {
      this.offsetDate = user?.customer?.utcOffset || "";
    });
  }

  stringTrim(string: string) {
    return string.replace(/\s/g, "");
  }

  isEmpty(value) {
    return (
      // null or undefined
      value == null ||
      value == undefined ||
      // has length and it's zero
      (value.hasOwnProperty("length") && value.length === 0) ||
      // is an Object and has no keys
      (value.constructor === Object && Object.keys(value).length === 0)
    );
  }

  navigateTo(node: any) {
    if (this.router.url === node.routelink) return;
    this.router.navigate([node.routelink]);
  }

  extractAllRouteParams(router: Router): any {
    const params: any = {};
    let route: ActivatedRouteSnapshot | null = router.routerState.snapshot.root;
    do {
      Object.keys(route.params).forEach(
        (key) => (params[key] = route?.params[key])
      );
      route = route.firstChild;
    } while (route);
    return params;
  }

  private slideOutCloseSubject = new BehaviorSubject<any>(null);
  slideOutClose$ = this.slideOutCloseSubject.asObservable();

  openSlideOutPanel(module: string, type: string, id = null, element = null) {
    const dialogRef = this.slideOutDialog.open(RightSlideOutPanelComponent, {
      panelClass: "slide-out-panel-dialog",
      disableClose: true,
      data: { module, type, id, element },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.slideOutCloseSubject.next(result);
      this.slideOutCloseSubject.complete();
    });
  }

  showSnackbar(type: number, message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type,
        message,
      },
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  convertLocalToUTC(localDate: string): string {
    const localDateObj = new Date(localDate);

    const year = localDateObj.getUTCFullYear();
    const month = String(localDateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(localDateObj.getUTCDate()).padStart(2, "0");
    const hours = String(localDateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(localDateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(localDateObj.getUTCSeconds()).padStart(2, "0");

    const utcDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return utcDate;
  }

  convertUTCToLocal(utcDate: string): string {
    const utcDateObj = new Date(utcDate + "Z");

    const year = utcDateObj.getFullYear();
    const month = String(utcDateObj.getMonth() + 1).padStart(2, "0");
    const day = String(utcDateObj.getDate()).padStart(2, "0");
    const hours = String(utcDateObj.getHours()).padStart(2, "0");
    const minutes = String(utcDateObj.getMinutes()).padStart(2, "0");
    const seconds = String(utcDateObj.getSeconds()).padStart(2, "0");

    const localDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return localDate;
  }

  generateTimeSlots(): string[] {
    const timeSlots: string[] = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);

    for (let i = 0; i < 48; i++) {
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();
      const period = hours < 12 ? "AM" : "PM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      const timeSlot = `${formattedHours}:${formattedMinutes} ${period}`;
      timeSlots.push(timeSlot);
      startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return timeSlots;
  }
}
