import { Component, Inject } from "@angular/core";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { MaterialModule } from "../../modules/material.module";
import { IconComponent } from "../icon/icon.component";
import { CommonModule } from "@angular/common";
import { SnackBarTypeEnum } from "../../../models/config";

@Component({
  selector: "app-snack-bar",
  standalone: true,
  imports: [CommonModule, MaterialModule, IconComponent],
  templateUrl: "./snack-bar.component.html",
  styleUrl: "./snack-bar.component.scss",
})
export class SnackBarComponent {
  message: string;
  type: SnackBarTypeEnum;
  snackBarStatus: string;
  private defaultMessages = {
    [SnackBarTypeEnum.Info]: "Information: Please be aware of this.",
    [SnackBarTypeEnum.Success]: "Operation completed successfully!",
    [SnackBarTypeEnum.Failure]: "Operation failed. Please try again.",
    [SnackBarTypeEnum.Reminder]: "Reminder: Please take note of this.",
    [SnackBarTypeEnum.Warning]: "Warning! Please check your input.",
    [SnackBarTypeEnum.Error]: "An error occurred. Please try again.",
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackBarComponent>
  ) {
    this.type = data.type;
    this.message = data.message || this.defaultMessages[this.type];
    this.snackBarStatus = this.getEnumKeyForValue(data.type) + " !";
  }

  getEnumKeyForValue(value: number): string {
    return SnackBarTypeEnum[value];
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
