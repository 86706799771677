<div class="right-slide-out-panel">
    @switch (matDialogData?.module) { @case ('customer') {
    <app-add-edit-customer
        [isEdit]="matDialogData?.type === 'edit'"
    ></app-add-edit-customer>
    } @case ('site') {
    <app-add-edit-site
        [isEdit]="matDialogData?.type === 'edit'"
    ></app-add-edit-site>
    } @case ('gateway') {
    <app-add-edit-gateway
        [type]="matDialogData?.type"
        [gatewayId]="this.matDialogData.id"
    ></app-add-edit-gateway>
    } @case ('report') {
    <app-generate-report
        [isEdit]="matDialogData?.type === 'edit'"
        [automatedReportData]="this.matDialogData.element"
    ></app-generate-report>
    }
    @case ('subscription') {
        <app-add-edit-subscription
            [isEdit]="matDialogData?.type === 'edit'"
            [subsId]="this.matDialogData.id"
        ></app-add-edit-subscription>
        }
        @case ('user') {
            <app-add-edit-user
                [isEdit]="matDialogData?.type === 'edit'"
                [user]="this.matDialogData"
            ></app-add-edit-user>
            }
    }
</div>
