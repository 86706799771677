import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../modules/material.module";
import { ThemeService } from "../../services/theme.service";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-icon",
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: "./icon.component.html",
  styleUrl: "./icon.component.scss",
})
export class IconComponent {
  @Input() title: string;
  @Input() action: string;
  @Input() disabled = false;
  @Input() width: number;
  @Input() state: string;
  @Output() clickEvent = new EventEmitter<any>();
  shareService: SharedService = inject(SharedService);

  getImgSrc(): string {
    this.width = 20;
    switch (this.action) {
      case "menu":
        return "../../../../assets/assets/icons/menu.svg";
      case "user":
        return "../../../../assets/assets/icons/user.svg";
      case "alarm":
        return "../../../../assets/assets/icons/alarm.svg";
      case "event":
        return "../../../../assets/assets/icons/event.svg";

      case "add":
        return "../../../../assets/assets/icons/add.svg";
      case "edit":
        return "../../../../assets/assets/icons/edit.svg";
      case "delete":
        return "../../../../assets/assets/icons/delete.svg";
      case "close":
        return "../../../../assets/assets/icons/close.svg";

      case "print":
        return "../../../../assets/assets/icons/temp/print.svg";
      case "download":
        return "../../../../assets/assets/icons/download.svg";

      case "search":
        return "../../../../assets/assets/icons/search.svg";
      case "filter":
        return "../../../../assets/assets/icons/filter.svg";
      case "share":
        return "../../../../assets/assets/icons/share.svg";
      case "message":
        return "../../../../assets/assets/icons/message.svg";

      case "extend":
        return "../../../../assets/assets/icons/extend.svg";
      case "grid":
        return "../../../../assets/assets/icons/temp/grid.svg";
      case "list":
        return "../../../../assets/assets/icons/list.svg";
      case "favourite":
        return "../../../../assets/assets/icons/favourite.svg";

      case "graphLink":
        return "../../../../assets/assets/icons/temp/graph-cards.svg";
      case "action":
        return "../../../../assets/assets/icons/temp/action.svg";
      case "tick":
        return "../../../../assets/assets/icons/temp/tick.svg";

      case "profile-edit":
        return "../../../../assets/assets/icons/temp/profile-edit.svg";

      case "pdf":
        return "../../../../assets/assets/icons/pdf.svg";

      /************************ */

      case "pan":
        return "../../../../assets/icons/pan.svg";

      // case 'alarm':
      //     return '../../../../assets/LK_icons/LK_2 Alert  Severe.svg'

      case "notification":
        return "../../../../assets/LK_icons/LK_Alarm.svg";

      case "critical-alarm":
        return "../../../../assets/LK_icons/LK_2 Alert  Severe.svg";
      case "high-alarm":
        return "../../../../assets/LK_icons/LK_2 Alert Alarm.svg";
      case "low-alarm":
        return "../../../../assets/LK_icons/LK_2 Alert Warning.svg";
      case "warning-alarm":
        return "../../../../assets/LK_icons/LK_White_2 Alert  Severe_grey.svg";
      case "notification-alarm":
        return "../../../../assets/LK_icons/PNG_Icons/LK_2 Alert .png";

      case "upload":
        return "../../../../assets/LK_icons/LK_upload.svg";
      case "view":
        return "../../../../assets/LK_icons/LK_Eye Open.svg";

      case "replace":
        return "../../../../assets/icons/replace.svg";

      case "move":
        return "../../../../assets/icons/unmap.svg";

      case "documentView":
        return "../../../../assets/LK_icons/LK_Audit.svg";

      case "acknowledge":
        return "../../../../assets/icons/tick.svg";

      // case 'search':
      //     return '../../../../assets/icons/search.svg'
      // case 'share':
      //     return '../../../../assets/icons/share.svg'
      case "favorite":
        return "../../../../assets/icons/favorite.svg";
      // case "pdf":
      //   return "../../../../assets/icons/pdf-lg.svg";
      case "breadcrumb":
        return "../../../../assets/LK_icons/LK_Organisation.svg";
      case "expand-kpi":
        return "../../../../assets/icons/arrow-trend-upp.svg";
      case "trends":
        return "../../../../assets/icons/trends.svg";
      case "zoom":
        return "../../../../assets/LK_icons/LK_Zoom out.svg";
      case "expand":
        return "../../../../assets/LK_icons/LK_Expand.svg";
      // case 'pan':
      //     return '../../../../assets/LK_icons/LK_Pan.svg'
      case "play":
        return "../../../../assets/LK_icons/LK_Arrow Right.svg";
      case "snackbar":
        return "../../../../assets/icons/snackbar.svg";

      default:
        return "";
    }
  }
}
